<template>
   
    <div id="top"> 
    <div id="back">
        <a href="/taskmanager" class="backbutton"> {{symbol }}任务面板</a>
      </div>  
    <div id="main" style="height:2000px" >
    
    </div>
    </div>
    </template>
    
    <script>
    // import { ref } from 'vue';
    import configure_options from '../configure'
    import emitter from "../eventbus.js"; 
    const $ = require('jquery');
    window.$ = $;
    import * as echarts from 'echarts';
    // function getCookieValue(a) {
    //     var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    //     return b ? b.pop() : '';
    // }
    
  
    //echarts.init(document.getElementById('main')).dispose();
    $.ajaxSetup({async:false});
    function  getgoaltrees(){
    var projectsfortree = []
    $.get("/v1/goalsgraph", function(data, status){
    console.log(status)
        var datafromserver = data
    //console.log(indicators.hist)
    //projects = datafromserver.projects
    var goals = datafromserver.goals
    // histv = indicators.hist
    // signalv = indicators.signal
    // kdjkv =  indicators.kdjk
    // kdjdv =  indicators.kdjd
    // kdjjv =  indicatof       rs.kdjj



   var  single_goal_projects = []
    for(var j=0;j<goals.length;j++){
        console.log("--------")
    //macdvalue.push(projects[i])
   
    projectsfortree = []
    for(var i=0;i<goals[j].Allprojectsingoal.length;i++){
     var allprojects = goals[j].Allprojectsingoal
    var  singleproject_tasks = []
    if(allprojects[i].Alltasksinproject!=null){

        for(var k=0;k<allprojects[i].Alltasksinproject.length;k++){
        var alltasks = allprojects[i].Alltasksinproject
        singleproject_tasks.push({"name":alltasks[k].task +"-"+alltasks[k].ID.toString(),"value":223})
     }
    }
     
     projectsfortree.push({"name":allprojects[i].Name,"children":singleproject_tasks})
     
    //singleproject_tasks.push({"name":alltasks[i].task +"-"+alltasks[i].ID.toString(),"value":223})
    }

    single_goal_projects.push({"name":goals[j].Name,"children":projectsfortree})

    }
    console.log("来自函数内部的数据部分")
    console.log(single_goal_projects)
    
    document.getElementById('main').removeAttribute('_echarts_instance_');
   
    var myChart = echarts.init(document.getElementById("main"),"dark");
   
        myChart.showLoading();
        // var data =single_goal_projects
        console.log("检测这里的数据部分")
        console.log(data)
        myChart.hideLoading();
       var newdata =  {
        "name": "flare",
        "children": [
        {
        "name": "analytics",
        "children": [
            {
            "name": "cluster",
            "children": [
            {"name": "AgglomerativeCluster", "value": 3938},
            {"name": "CommunityStructure", "value": 3812},
            {"name": "HierarchicalCluster", "value": 6714},
            {"name": "MergeEdge", "value": 743}
            ]
            },
            {
            "name": "graph",
            "children": [
            {"name": "BetweennessCentrality", "value": 3534},
            {"name": "LinkDistance", "value": 5731},
            {"name": "MaxFlowMinCut", "value": 7840},
      {"name": "ShortestPaths", "value": 5914},
      {"name": "SpanningTree", "value": 3416}
     ]
    },
    {
     "name": "optimization",
     "children": [
      {"name": "AspectRatioBanker", "value": 7074}
     ]
    }
   ]
  },
  {
   "name": "animate",
   "children": [
    {"name": "Easing", "value": 17010},
    {"name": "FunctionSequence", "value": 5842},
    {
     "name": "interpolate",
     "children": [
      {"name": "ArrayInterpolator", "value": 1983},
      {"name": "ColorInterpolator", "value": 2047},
      {"name": "DateInterpolator", "value": 1375},
      {"name": "Interpolator", "value": 8746},
      {"name": "MatrixInterpolator", "value": 2202},
      {"name": "NumberInterpolator", "value": 1382},
      {"name": "ObjectInterpolator", "value": 1629},
      {"name": "PointInterpolator", "value": 1675},
      {"name": "RectangleInterpolator", "value": 2042}
     ]
    },
    {"name": "ISchedulable", "value": 1041},
    {"name": "Parallel", "value": 5176},
    {"name": "Pause", "value": 449},
    {"name": "Scheduler", "value": 5593},
    {"name": "Sequence", "value": 5534},
    {"name": "Transition", "value": 9201},
    {"name": "Transitioner", "value": 19975},
    {"name": "TransitionEvent", "value": 1116},
    {"name": "Tween", "value": 6006}
   ]
  },
  {
   "name": "data",
   "children": [
    {
     "name": "converters",
     "children": [
      {"name": "Converters", "value": 721},
      {"name": "DelimitedTextConverter", "value": 4294},
      {"name": "GraphMLConverter", "value": 9800},
      {"name": "IDataConverter", "value": 1314},
      {"name": "JSONConverter", "value": 2220}
     ]
    },
    {"name": "DataField", "value": 1759},
    {"name": "DataSchema", "value": 2165},
    {"name": "DataSet", "value": 586},
    {"name": "DataSource", "value": 3331},
    {"name": "DataTable", "value": 772},
    {"name": "DataUtil", "value": 3322}
   ]
  },
  {
   "name": "display",
   "children": [
    {"name": "DirtySprite", "value": 8833},
    {"name": "LineSprite", "value": 1732},
    {"name": "RectSprite", "value": 3623},
    {"name": "TextSprite", "value": 10066}
   ]
  },
  {
   "name": "flex",
   "children": [
    {"name": "FlareVis", "value": 4116}
   ]
  },
  {
   "name": "physics",
   "children": [
    {"name": "DragForce", "value": 1082},
    {"name": "GravityForce", "value": 1336},
    {"name": "IForce", "value": 319},
    {"name": "NBodyForce", "value": 10498},
    {"name": "Particle", "value": 2822},
    {"name": "Simulation", "value": 9983},
    {"name": "Spring", "value": 2213},
    {"name": "SpringForce", "value": 1681}
   ]
  },
  {
   "name": "query",
   "children": [
    {"name": "AggregateExpression", "value": 1616},
    {"name": "And", "value": 1027},
    {"name": "Arithmetic", "value": 3891},
    {"name": "Average", "value": 891},
    {"name": "BinaryExpression", "value": 2893},
    {"name": "Comparison", "value": 5103},
    {"name": "CompositeExpression", "value": 3677},
    {"name": "Count", "value": 781},
    {"name": "DateUtil", "value": 4141},
    {"name": "Distinct", "value": 933},
    {"name": "Expression", "value": 5130},
    {"name": "ExpressionIterator", "value": 3617},
    {"name": "Fn", "value": 3240},
    {"name": "If", "value": 2732},
    {"name": "IsA", "value": 2039},
    {"name": "Literal", "value": 1214},
    {"name": "Match", "value": 3748},
    {"name": "Maximum", "value": 843},
    {
     "name": "methods",
     "children": [
      {"name": "add", "value": 593},
      {"name": "and", "value": 330},
      {"name": "average", "value": 287},
      {"name": "count", "value": 277},
      {"name": "distinct", "value": 292},
      {"name": "div", "value": 595},
      {"name": "eq", "value": 594},
      {"name": "fn", "value": 460},
      {"name": "gt", "value": 603},
      {"name": "gte", "value": 625},
      {"name": "iff", "value": 748},
      {"name": "isa", "value": 461},
      {"name": "lt", "value": 597},
      {"name": "lte", "value": 619},
      {"name": "max", "value": 283},
      {"name": "min", "value": 283},
      {"name": "mod", "value": 591},
      {"name": "mul", "value": 603},
      {"name": "neq", "value": 599},
      {"name": "not", "value": 386},
      {"name": "or", "value": 323},
      {"name": "orderby", "value": 307},
      {"name": "range", "value": 772},
      {"name": "select", "value": 296},
      {"name": "stddev", "value": 363},
      {"name": "sub", "value": 600},
      {"name": "sum", "value": 280},
      {"name": "update", "value": 307},
      {"name": "variance", "value": 335},
      {"name": "where", "value": 299},
      {"name": "xor", "value": 354},
      {"name": "-", "value": 264}
     ]
    },
    {"name": "Minimum", "value": 843},
    {"name": "Not", "value": 1554},
    {"name": "Or", "value": 970},
    {"name": "Query", "value": 13896},
    {"name": "Range", "value": 1594},
    {"name": "StringUtil", "value": 4130},
    {"name": "Sum", "value": 791},
    {"name": "Variable", "value": 1124},
    {"name": "Variance", "value": 1876},
    {"name": "Xor", "value": 1101}
   ]
  },
  {
   "name": "scale",
   "children": [
    {"name": "IScaleMap", "value": 2105},
    {"name": "LinearScale", "value": 1316},
    {"name": "LogScale", "value": 3151},
    {"name": "OrdinalScale", "value": 3770},
    {"name": "QuantileScale", "value": 2435},
    {"name": "QuantitativeScale", "value": 4839},
    {"name": "RootScale", "value": 1756},
    {"name": "Scale", "value": 4268},
    {"name": "ScaleType", "value": 1821},
    {"name": "TimeScale", "value": 5833}
   ]
  },
  {
   "name": "util",
   "children": [
    {"name": "Arrays", "value": 8258},
    {"name": "Colors", "value": 10001},
    {"name": "Dates", "value": 8217},
    {"name": "Displays", "value": 12555},
    {"name": "Filter", "value": 2324},
    {"name": "Geometry", "value": 10993},
    {
     "name": "heap",
     "children": [
      {"name": "FibonacciHeap", "value": 9354},
      {"name": "HeapNode", "value": 1233}
     ]
    },
    {"name": "IEvaluable", "value": 335},
    {"name": "IPredicate", "value": 383},
    {"name": "IValueProxy", "value": 874},
    {
     "name": "math",
     "children": [
      {"name": "DenseMatrix", "value": 3165},
      {"name": "IMatrix", "value": 2815},
      {"name": "SparseMatrix", "value": 3366}
     ]
    },
    {"name": "Maths", "value": 17705},
    {"name": "Orientation", "value": 1486},
    {
     "name": "palette",
     "children": [
      {"name": "ColorPalette", "value": 6367},
      {"name": "Palette", "value": 1229},
      {"name": "ShapePalette", "value": 2059},
      {"name": "SizePalette", "value": 2291}
     ]
    },
    {"name": "Property", "value": 5559},
    {"name": "Shapes", "value": 19118},
    {"name": "Sort", "value": 6887},
    {"name": "Stats", "value": 6557},
    {"name": "Strings", "value": 22026}
   ]
  },
  {
   "name": "vis",
   "children": [
    {
     "name": "axis",
     "children": [
      {"name": "Axes", "value": 1302},
      {"name": "Axis", "value": 24593},
      {"name": "AxisGridLine", "value": 652},
      {"name": "AxisLabel", "value": 636},
      {"name": "CartesianAxes", "value": 6703}
     ]
    },
    {
     "name": "controls",
     "children": [
      {"name": "AnchorControl", "value": 2138},
      {"name": "ClickControl", "value": 3824},
      {"name": "Control", "value": 1353},
      {"name": "ControlList", "value": 4665},
      {"name": "DragControl", "value": 2649},
      {"name": "ExpandControl", "value": 2832},
      {"name": "HoverControl", "value": 4896},
      {"name": "IControl", "value": 763},
      {"name": "PanZoomControl", "value": 5222},
      {"name": "SelectionControl", "value": 7862},
      {"name": "TooltipControl", "value": 8435}
     ]
    },
    {
     "name": "data",
     "children": [
      {"name": "Data", "value": 20544},
      {"name": "DataList", "value": 19788},
      {"name": "DataSprite", "value": 10349},
      {"name": "EdgeSprite", "value": 3301},
      {"name": "NodeSprite", "value": 19382},
      {
       "name": "render",
       "children": [
        {"name": "ArrowType", "value": 698},
        {"name": "EdgeRenderer", "value": 5569},
        {"name": "IRenderer", "value": 353},
        {"name": "ShapeRenderer", "value": 2247}
       ]
      },
      {"name": "ScaleBinding", "value": 11275},
      {"name": "Tree", "value": 7147},
      {"name": "TreeBuilder", "value": 9930}
     ]
    },
    {
     "name": "events",
     "children": [
      {"name": "DataEvent", "value": 2313},
      {"name": "SelectionEvent", "value": 1880},
      {"name": "TooltipEvent", "value": 1701},
      {"name": "VisualizationEvent", "value": 1117}
     ]
    },
    {
     "name": "legend",
     "children": [
      {"name": "Legend", "value": 20859},
      {"name": "LegendItem", "value": 4614},
      {"name": "LegendRange", "value": 10530}
     ]
    },
    {
     "name": "operator",
     "children": [
      {
       "name": "distortion",
       "children": [
        {"name": "BifocalDistortion", "value": 4461},
        {"name": "Distortion", "value": 6314},
        {"name": "FisheyeDistortion", "value": 3444}
       ]
      },
      {
       "name": "encoder",
       "children": [
        {"name": "ColorEncoder", "value": 3179},
        {"name": "Encoder", "value": 4060},
        {"name": "PropertyEncoder", "value": 4138},
        {"name": "ShapeEncoder", "value": 1690},
        {"name": "SizeEncoder", "value": 1830}
       ]
      },
      {
       "name": "filter",
       "children": [
        {"name": "FisheyeTreeFilter", "value": 5219},
        {"name": "GraphDistanceFilter", "value": 3165},
        {"name": "VisibilityFilter", "value": 3509}
       ]
      },
      {"name": "IOperator", "value": 1286},
      {
       "name": "label",
       "children": [
        {"name": "Labeler", "value": 9956},
        {"name": "RadialLabeler", "value": 3899},
        {"name": "StackedAreaLabeler", "value": 3202}
       ]
      },
      {
       "name": "layout",
       "children": [
        {"name": "AxisLayout", "value": 6725},
        {"name": "BundledEdgeRouter", "value": 3727},
        {"name": "CircleLayout", "value": 9317},
        {"name": "CirclePackingLayout", "value": 12003},
        {"name": "DendrogramLayout", "value": 4853},
        {"name": "ForceDirectedLayout", "value": 8411},
        {"name": "IcicleTreeLayout", "value": 4864},
        {"name": "IndentedTreeLayout", "value": 3174},
        {"name": "Layout", "value": 7881},
        {"name": "NodeLinkTreeLayout", "value": 12870},
        {"name": "PieLayout", "value": 2728},
        {"name": "RadialTreeLayout", "value": 12348},
        {"name": "RandomLayout", "value": 870},
        {"name": "StackedAreaLayout", "value": 9121},
        {"name": "TreeMapLayout", "value": 9191}
       ]
      },
      {"name": "Operator", "value": 2490},
      {"name": "OperatorList", "value": 5248},
      {"name": "OperatorSequence", "value": 4190},
      {"name": "OperatorSwitch", "value": 2581},
      {"name": "SortOperator", "value": 2023}
     ]
    },
    {"name": "Visualization", "value": 16540}
   ]
  }
 ]
}

       
newdata = {"name":"goals","children":single_goal_projects}
myChart.setOption(
          {
            
           
           tooltip: {
               trigger: 'item',
               triggerOn: 'mousemove',
               extraCssText: 'max-width:200px; white-space:pre-wrap',
               formatter: function(params) {
              return `<div>${params.data.name}</div>`//(1)看我看我！！！
          }
           },
           series: [

            {

          type: 'tree',
          data: [newdata],
          darkMode: "auto",
        //   top: '18%',
        //   bottom: '14%',
          layout: 'radial',
          symbol: 'emptyCircle',
          symbolSize: 7,
          initialTreeDepth: 3,
          animationDurationUpdate: 750,
           extraCssText: 'max-width:200px; white-space:pre-wrap',
          label: {
                //   position: 'right',
                  verticalAlign: 'middle',
                //   align: 'left',
                  fontSize: 12,
                  width: 180,//指定宽度，(2)看我看我！！！
                  // height: 100,
                  lineHeight: 20,
                  padding: [4, 4, 4, 4],
                  overflow: 'break' //文字过多换行，/(2)看我看我！！！
              },

          emphasis: {
            focus: 'descendant'
          }
        }


           ]
       
      
      
        }
       );
     
            return single_goal_projects
        })

}

    
    function gettoday(){
        var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = yyyy.toString().substring(2,4)+mm+dd  ;
    return today
    }
    
    //https://stackoverflow.com/questions/25446628/ajax-jquery-refresh-div-every-5-seconds
    
    // var resize = function() {
    //       myChart.resize({
    //         // width: window.innerWidth ,
    //         // height: window.innerHeight
    //         // width: "700px" ,
    //         // height: "600px"
    //         width: $(window).width(),
    //         height: $(window).height()
    //       });
    //     };
    
      
    
    //     //窗口变动时自适应
    //     window.onresize = function() {
    //       resize();
    //     };
    
         // 基于准备好的dom，初始化echarts实例 http://echarts.baidu.com/examples/#chart-type-treemap
          //  var myChart = echarts.init(document.getElementById('main'));
    //https://stackoverflow.com/questions/133310/how-can-i-get-jquery-to-perform-a-synchronous-rather-than-asynchronous-ajax-re
    //https://stackoverflow.com/questions/25488915/async-false-for-shorthand-ajax-get-request
  
    
    
    
    
    function loadlink(myChart){
    //var standard_group = ['totalscore',"todayscore",'doanimportantthingearly','markataskimmediately','alwaysprofit','patience','usebrain','battlewithlowerbrain','learnnewthings','makeofthingsuhavelearned','difficultthings','threeminutes','getlesson','learntechuse','thenumberoftasks_score','serviceforgoal_score'];
    var datecategory = [];
    var totalscore = [];
    var todayscore = [];
    var patience = [];
    var alwaysprofit = [];
    var usebrain = [];
    var battlewithlowerbrain = [];
    var learnnewthings = [];
    var makeuseofthingsuhavelearned = [];
    var difficultthings = [];
    var threeminutes = [];
    var getlesson = [];
    var learntechuse = [];
    var thenumberoftasks_score = [];
    var serviceforgoal_score = [];
    var onlystartatask_score = [];
    var atomadifficulttask_score = []; 
    var doanimportantthingearly = [];
    var markataskimmediately = [];
    var buildframeandprinciple = [];
    var acceptfact = [];
    var executeablity = [];
    
    
      var env =  configure_options["env"]
           
            console.log(configure_options["app_setting"][env])
            var serverhost =configure_options["app_setting"][env]["serverhost"]
    
    
    
    $.get(serverhost + "/v1/reviewdaydatajson", function(data){
           // alert("Data: " + data.reviewdata[0].ID + "\nStatus: " + status);
        //    alert(data.reviewdata.length)
            for(var i=0;i<data.reviewdata.length;i++){
              
    
    
        datecategory.push(data.reviewdata[i].date);
        if (data.reviewdata[i].details != ""){
        var obj_detailofreview = JSON.parse(data.reviewdata[i].details);
        // alert(obj_detailofreview)
    totalscore.push(obj_detailofreview.totalscore);
    //    totalscore.push(11);
    patience.push(obj_detailofreview["patience"]);
    // alert(obj_detailofreview.patience);
    usebrain.push(obj_detailofreview["usebrain"]);
    // alert(obj_detailofreview.usebrain);
    battlewithlowerbrain.push(obj_detailofreview.battlewithlowerbrain);
    learnnewthings.push(obj_detailofreview.learnnewthings);
    makeuseofthingsuhavelearned.push(obj_detailofreview.makeuseofthingsuhavelearned);
    difficultthings.push(obj_detailofreview.difficultthings);
    threeminutes.push(obj_detailofreview.threeminutes);
    executeablity.push(obj_detailofreview.executeability_score*obj_detailofreview.totalscore);
    getlesson.push(obj_detailofreview.getlesson);
    learntechuse.push(obj_detailofreview.learntechuse);
    alwaysprofit.push(obj_detailofreview.alwaysprofit);
    thenumberoftasks_score.push(obj_detailofreview.thenumberoftasks_score);
    serviceforgoal_score.push(obj_detailofreview.serviceforgoal_score);
    onlystartatask_score.push(obj_detailofreview.onlystartatask_score);
    atomadifficulttask_score.push(obj_detailofreview.atomadifficulttask);
    doanimportantthingearly.push(obj_detailofreview.doanimportantthingearly);
    markataskimmediately.push(obj_detailofreview.markataskimmediately);
    buildframeandprinciple.push(obj_detailofreview.buildframeandprinciple);
    acceptfact.push(obj_detailofreview.acceptfact)
        }else{
    
        totalscore.push(0);
    patience.push(0);
    // alert(obj_detailofreview.patience);
    usebrain.push(0);
    // alert(obj_detailofreview.usebrain);
    battlewithlowerbrain.push(0);
    learnnewthings.push(0);
    makeuseofthingsuhavelearned.push(0);
    difficultthings.push(0);
    threeminutes.push(0);
    executeablity.push(0);
    getlesson.push(0);
    learntechuse.push(0);
    alwaysprofit.push(0);
    thenumberoftasks_score.push(0);
    serviceforgoal_score.push(0);
    onlystartatask_score.push(0);
    atomadifficulttask_score.push(0);
    doanimportantthingearly.push(0);
    markataskimmediately.push(0);
    buildframeandprinciple.push(0);
    acceptfact.push(0)
    
    
        }
    
              
             if (data.reviewdata[i].date===gettoday()){break;}
             
    }
    
    
    
    
    
    
    
    
    //添加一条今日线
    todayscore = new Array(totalscore.length).fill(totalscore[totalscore.length-1]);
    // todayscore = new Array(totalscore.length).fill(0.8);
    // alert(todayscore)
    //alert(serviceforgoal_score);
    /*
    
    alert(totalscore);
    alert(datecategory);
    alert(patience);
    alert(makeuseofthingsuhavelearned);
    alert(learnnewthings);
    alert(usebrain);
    alert(battlewithlowerbrain);
    
    */
    console.log()
    console.log(datecategory)
    console.log(todayscore)
    }
    );
    
    
    //finance api
    // var thisyear = 10000
    //var left = 100090
    //这里是请求的老一点的api，是通过计算每一个订单流程来的
    // $.get("/finance/getrewardleft", function(data, status){
    //     /*
        
    //     {"left":finance["left"],"available":40,"budget":90,"thisyear":finance["thisyear"],"code":200}
    //     */
    //     var k = JSON.parse(data)
    //         thisyear = k.thisyear
    //         left = k.left
    //         // alert(data)
    //         // alert(left)     
    // });  
    
    
    // $.get("/finance/balance", function(data){
    //     /*
        
    //     {"left":finance["left"],"available":40,"budget":90,"thisyear":finance["thisyear"],"code":200}
    //     */
    //     var k = JSON.parse(data)
    //         // thisyear = k.thisyear
    //         left = k.asset
    //         // alert(data)
    //         // alert(left)     
    // }); 
    
    
    function gettodayreviewnumber(){
        var number = 0
        if (todayscore.length>0){
            number = totalscore[totalscore.length - 1].toFixed(2)
        }else{
            number = 0
        }
        
    
        return number 
    }
    // alert(thisyear)
    
    myChart.showLoading();
    myChart.hideLoading();
    //var datafromserver = ['yangming','is','181923','201942','周五','周六','周日'];
    myChart.clear();
    myChart.setOption(
     {
        // graphic: { // 一个图形元素，类型是 text，指定了 id。
        //         type: 'text',
        //         id: 'text1'
        //     },
        title: {
            text: 'Evaluation system'
        },
    
    
        // graphic:{ // 一个图形元素，类型是 text，指定了 id。
        //         type: 'text',
        //         id: 'text1',
        //         style: {
        //         text: totalscore[totalscore.length - 1].toFixed(2),
        //         x: 100,
        //         y: 200
        //       }
        //     },
    
    
        tooltip : {
            trigger: 'none',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
      
        legend: {
            data:['Totalscore','MA5','MA10','Make use of things u have learned','The total score of  number of tasks finished','Start an important thing early']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
            graphic:[{ // 一个图形元素，类型是 text，指定了 id。
                type: 'text',
                id: 'text2',
                style: {
                // text: totalscore[totalscore.length - 1].toFixed(2),
                text:gettodayreviewnumber(),
                   x: myChart.getZr().getWidth()*4/5,
                y: myChart.getZr().getHeight()/3, 
    
       fill: 'red',
                    stroke: '#999',
    //    textAlign: 'center', 
       textFont : 'bold 50px yellow'
        }
            },
    //         { // 一个图形元素，类型是 text，指定了 id。
    //             type: 'text',
    //             id: 'text0',
    //             style: {
    //             text: left.toFixed(1),
    //             x: myChart.getZr().getWidth()*1/5,
    //             y: myChart.getZr().getHeight()/3, 
    //    textFill:'green',
    // //    textAlign: 'center', 
    //    textFont : 'bold 50px verdana'
    //     }
    //         },
            ],
    
    
        xAxis : [
            {
                type : 'category',
                boundaryGap : false,
                data : datecategory
            }
        ],
        yAxis : [
            {
                type : 'value'
            }
        ],
        series : [
    /*
    
        {
                name:'Make use of things you have learned',
                type:'line',
                stack: 'make',
                areaStyle: {},
                data:makeuseofthingsuhavelearned
            },
    
            // {
            //     name:'The total score of  number of tasks finished',
            //     type:'line',
            //     stack: 'number',
            //     areaStyle: {},
            //     data:thenumberoftasks_score
            // },
    
    
    
    
         
            {
                name:'Service for goal',
                type:'line',
                stack: 'serviceforgoal',
                areaStyle: {},
                data:serviceforgoal_score
            },
    
    
    
    
                   {
                name:'Atom a difficult task',
                type:'line',
                stack: 'atomadifficulttask',
                areaStyle: {},
                data:atomadifficulttask_score
            },
    
    
    
    
    
                {
                name:'Only start a task',
                type:'line',
                stack: 'onlystartatask',
                areaStyle: {},
                data:onlystartatask_score
            },
    
    
    
    
        {
                name:'Finish difficult things',
                type:'line',
                stack: 'difficult',
                areaStyle: {},
                data:difficultthings
            },
    
        {
                name:'Threeminutes',
                type:'line',
                stack: 'three',
                areaStyle: {},
                data:threeminutes
            },
    
        {
                name:'Alwaysproft',
                type:'line',
                stack: 'three',
                areaStyle: {},
                data:alwaysprofit
            },
    
    
        {
                name:'Getlesson',
                type:'line',
                stack: 'getlesson',
                areaStyle: {},
                data:getlesson
            },
    
        
    
    
    
    
        {
                name:'Learntechuse',
                type:'line',
                stack: 'learntech',
                areaStyle: {},
                data:learntechuse
            },
    
    */
     
     /*    
      
    
    
    
        {
                name:'Start an important thing early',
                type:'line',
                stack: 'learntech',
                areaStyle: {},
                data:doanimportantthingearly
            },
    
        {
                name:'Mark a task immediately',
                type:'line',
                stack: 'learntech',
                areaStyle: {},
                data:markataskimmediately
            },
    
    
    
    
       {
                name:'Use brain to deal with things',
                type:'line',
                stack: 'brain',
                areaStyle: {},
                data:usebrain
            },
    
     {
                name:'Patience with the task',
                type:'line',
                stack: 'patience',
                areaStyle: {},
                data:patience
            },
    
    
    
      {
                name:'Battle with lower brain',
                type:'line',
                stack: 'battle',
                areaStyle: {},
                data:battlewithlowerbrain
            },
    
    
    
       {
                name:'Learn new things',
                type:'line',
                stack: 'learn',
                areaStyle: {},
                data:learnnewthings
            },
    
            {
                name:'Build frame and principle',
                type:'line',
                stack: 'learn',
                areaStyle: {},
                data:buildframeandprinciple
            },
            {
                name:'Accept fact ',
                type:'line',
                stack: 'learn',
                areaStyle: {},
                data:acceptfact
            },
    
    
            {
                    name: 'MA5',
                    type: 'line',
                    color:'red',
                    data: calculateMA(5, totalscore),
                    smooth: true,
                    lineStyle: {
                        normal: { opacity: 0.5 }
                    }
                },
    
                {
                    name: 'MA10',
                    type: 'line',
                    color:'black',
                    data: calculateMA(10, totalscore),
                    smooth: true,
                    lineStyle: {
                        normal: { opacity: 0.5 }
                    }
                },
    
    
    
    */
    
      //同时在图像中添加别的类型的曲线
           
    //         {
    //             name:'Executeability',
    //             type:'line',
    //             color:"brown",
    //   //          stack: 'e',
    // //            areaStyle: {},
    //             data:executeablity,
    //             smooth: true,
    //                 lineStyle: {
    //                     normal: { opacity: 1.0, 
    //                     width:2,
                        
    //                     }
    
    //                 }
    
    
    
    
    //         },
    
    
     {
                name:'todayscore',
                type:'line',
                // stack: 'e',
                color:'red',
                // areaStyle: {},
                data:todayscore,
                symbolSize:0,
                symbol:'none',
                smooth: true,
                    lineStyle: {
                        normal: { opacity: 1.0, 
                        width:5
                        }
    
                    }
    
    
            },
            {
                name:'Totalscore',
                type:'line',
                smooth: true,
                color:'blue',
                symbolSize:1,
                //是否设置对应的symbol的大小
                // symbol:'none',
    //            stack: 'e',
              
    /*
      label: {
                    normal: {
                        show: true,
                        position: 'top'
                    }
                },
                areaStyle: {normal: {}},
    */  
                    lineStyle: {
                        normal: { opacity: 1,
                        width:2
                        }
    
                    },
              data:totalscore
            }
        ]
    }
    );
    }
    
    
    
    
    
    
    // setInterval(function(){
    //     loadlink() // this will run after every 5 seconds
    // }, 10*60000);
    
    
    
    
    
    
    
    export default {
      name: 'reference',
      props: {
        msg: String
      },
      data(){
        return{
            symbol:"<",
        }
      },

      mounted(){
        // const echart = ref("main"+Date.now()+Math.random())
        document.getElementById('main').removeAttribute('_echarts_instance_');
    
        var myChart = echarts.init(document.getElementById("main"));
         // resize();
        loadlink(myChart); // This will run on page load
    
        emitter.on("refresh_review",msg=>{
        //   alert("检查是否被盗用")
            console.log("review组件收到更新请求",msg);
            if(msg=="from_taskrecursion_component"){
              loadlink(myChart); 
              // alert("检测事件是否被激发")
            }
            
             if(msg=="from_taskedit_component"){
              loadlink(myChart); 
              // alert("检测事件是否被激发")
            }
         
        });
    
    

       getgoaltrees()


    
      },
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="scss">
       #top{
        /* height:100%  
         */
         position: absolute;
         width: 100vw;
        height: 100vh;
        overflow-y:scroll;
        overflow-x:auto;
       background: #100c2a;
    }




   #back{
       display: flex;
    //    flex-direction: column;
       .backbutton{
        font-family: "handwriting1";
        margin-left:auto
       }

    }
    /* hide  scroll bar */
    /* https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */
    ::-webkit-scrollbar { 
        display: none; 
    }
    
    </style>
    